import { Box, Text } from "flicket-ui";
import { SourceSwitcherDropdown } from "~features/reports/reporting/components/SourceSwitcher/SourceSwitcher";
import { FolderTabs, Icon, Select as StyledSelect } from "~components";
import { CaretDown } from "@phosphor-icons/react";
import React, { useContext, useEffect, useState } from "react";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import {
  groupEvents,
  groupMemberships,
} from "~features/reports/reporting/components/SourceSwitcher/util";
import Select, { components, OptionProps } from "react-select";
import {
  renderGroup,
  selectStyles,
} from "~features/reports/reporting/components/SourceSwitcher/Select/common";
import { getUrl } from "~lib";
import { useOrganization } from "~hooks";
import { DefaultTheme, useTheme } from "styled-components";
import {
  EventSearchItem,
  SearchItem,
} from "~features/reports/reporting/components/SourceSwitcher/SearchItem";
import { useReleases } from "~graphql";
import { SourceControlContext } from "~features/broadcast/context/sourceControl.context";

interface SourceSelectProps {
  searchableMemberships?: any;
  searchableEvents?: any;
  onChange: (option: { id: string; title: string }) => void;
  theme: DefaultTheme;
}

const SeasonDropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Box mr={"6px" as any}>
      <CaretDown size={14} color="N800" fontWeight="bold" />
    </Box>
  </components.DropdownIndicator>
);

const Option = (props: OptionProps<any, false>) => (
  <components.Option {...props}>
    {props.data.name && (
      <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
    )}
    {props.data.title && (
      <EventSearchItem searchEvent={props.data} active={props.isFocused} />
    )}
  </components.Option>
);

const SourceSelect = ({
  searchableEvents,
  searchableMemberships,
  onChange,
  theme,
}: SourceSelectProps) => {
  const options = searchableEvents
    ? groupEvents(searchableEvents)
    : groupMemberships(searchableMemberships);
  const placeholderText = searchableEvents
    ? "Search events"
    : "Search memberships";

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: any) => option.id}
      getOptionLabel={(option: any) => option.title ?? option.name}
      formatGroupLabel={renderGroup}
      onChange={onChange}
      styles={selectStyles(theme)}
      components={{ Option }}
      placeholder={placeholderText}
    />
  );
};

const getIdFromUrl = (
  url: string,
  type: "events" | "memberships"
): string | null => {
  const match = url.match(
    new RegExp(
      `${type}/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})`
    )
  );
  return match ? match[1] : null;
};

const getReleaseNameFromUrl = (url: string): string | null => {
  const match = url.match(/release\/([^\/?]*)/);
  return match ? decodeURIComponent(match[1]) : null;
};

interface EventMembershipDropDownProps {
  onChange: ({ url, name }: { url: string; name: string }) => void;
  selected: { content: string; url: string };
}

export const EventMembershipDropDown = ({
  onChange,
  selected,
}: EventMembershipDropDownProps) => {
  const data = useContext(SourceControlContext);
  const { organization } = useOrganization();
  const baseUrl = getUrl(organization?.slug, false);
  const theme = useTheme();

  const [eventId, setEventId] = useState<string | null>(null);
  const [membershipId, setMembershipId] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setEventId(getIdFromUrl(selected.url, "events"));
    setMembershipId(getIdFromUrl(selected.url, "memberships"));
  }, [selected]);

  const { data: releaseData } = useReleases(eventId);
  const eventName =
    data.reportingSearchIndex.searchableEvents.find(
      (item: any) => item.id === eventId
    )?.title ?? null;
  const membershipName =
    data.reportingSearchIndex.searchableMemberships.find(
      (item: any) => item.id === membershipId
    )?.name ?? null;
  const releaseName = getReleaseNameFromUrl(selected.url);

  const onReleaseIdChange = (releaseName: string) => {
    onChange({
      url: `${selected.url}/release/${releaseName}`,
      name: `${selected.content} - ${releaseName}`,
    });
  };

  const onSelect = (option: { title?: string; id: string; name?: string }) => {
    const url = option.title
      ? `${baseUrl}/events/${option.id}`
      : `${baseUrl}/memberships/${option.id}`;
    const name = option.title || option.name;
    if (option.title) setEventId(option.id);
    onChange({ url, name });
  };

  const createNavTab = (key: string, name: string, dataSource: any) => ({
    key,
    name,
    content: (props: any) => (
      <SourceSelect
        theme={theme}
        onChange={(option) => {
          onSelect(option);
          props.setIsOpen(false);
        }}
        {...dataSource}
      />
    ),
  });

  const primaryNavTabs = [
    createNavTab(PRIMARY_NAVIGATION_KEYS.EVENTS, "Events", {
      searchableEvents: data.reportingSearchIndex.searchableEvents,
    }),
    ...(data.reportingSearchIndex.searchableMemberships.length > 0
      ? [
          createNavTab(PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS, "Memberships", {
            searchableMemberships:
              data.reportingSearchIndex.searchableMemberships,
          }),
        ]
      : []),
  ];

  return (
    <Box>
      <Text fontSize={"16px" as any} mb={1}>
        Event or Membership
      </Text>
      <SourceSwitcherDropdown
        zIndex={1500}
        isTextButton
        dropdownContent={(props) => (
          <FolderTabs
            activeIndex={activeIndex}
            onTabChange={setActiveIndex}
            items={primaryNavTabs.map((item) => ({
              ...item,
              content: item.content(props),
            }))}
            tabContentWrapperProps={{
              padding: 0,
              borderRadius: "none",
              background: "none",
            }}
          />
        )}
        buttonContent={
          <ButtonContent text={eventName ?? membershipName ?? null} />
        }
      />
      {releaseData?.length > 0 && (
        <>
          <Text fontSize={"16px" as any} mt={4} mb={1}>
            Release
          </Text>
          <StyledSelect
            options={releaseData.map((release: any) => ({
              label: release.name,
              value: release.name,
            }))}
            value={releaseName}
            onChange={onReleaseIdChange}
            components={{ DropdownIndicator: SeasonDropdownIndicator }}
          />
        </>
      )}
    </Box>
  );
};

const ButtonContent = ({ text }: { text?: string }) => (
  <Box display="flex" width="100%" justifyContent="space-between">
    <Text color={text ? "N800" : "N400"} fontSize={"16px" as any}>
      {text ?? "Select event or membership..."}
    </Text>
    <Icon icon={<CaretDown size={14} weight="bold" />} className="icon" />
  </Box>
);
